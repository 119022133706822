<template>
  <el-card class="box-card">
    <el-radio-group
      v-model="tabType"
      style="margin-top: 20px"
      @change="typeChange"
    >
      <el-radio-button label="本周"></el-radio-button>
      <el-radio-button label="本月"></el-radio-button>
      <el-radio-button label="本年"></el-radio-button>
    </el-radio-group>

    <el-tabs class="tabType" v-model="tabType">
      <el-tab-pane label name="本周">
        <div ref="echartBox1" style="width: 100%; height: 400px"></div>
      </el-tab-pane>
      <el-tab-pane label name="本月">
        <div ref="echartBox2" style="width: 100%; height: 400px"></div>
      </el-tab-pane>
      <el-tab-pane label name="本年">
        <div ref="echartBox3" style="width: 100%; height: 400px"></div>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import { api } from '/src/api/base';
export const dashboardCompare = api()(
  'dashboard.agent.compare.enterprise.json',
);
export default {
  data () {
    return {
      tabType: '本周',
      data: null,
      dataLine: null,
    };
  },

  async mounted () {
    await this.getData();
    this.setOption('echartBox1');
  },
  methods: {
    async getData () {
      const res = await dashboardCompare({
        type:
          this.tabType === '本周'
            ? 'week'
            : this.tabType === '本月'
              ? 'month'
              : 'year',
      });
      this.data = res;
      this.dataLine = Object.keys(this.data).map((it) => it);
    },
    setOption (echart) {
      var myChart = window.echarts.init(this.$refs[echart]);
      let serverData = [];
      Object.keys(this.data).forEach((it) => {
        serverData.push({
          name: it,
          type: 'line',
          smooth: true,
          symbolSize: 8,
          symbol: 'circle',

          data: this.data[it].map((it) => it.total),
        });
      });
      var option = {
        title: {
          text: '经纪人业务拓展趋势图',
          left: '12%',
          top: '3%',
          textStyle: {
            color: '#000000',
            fontWeight: 'bolder',
            fontSize: 17,
          },
        },
        legend: {
          orient: 'horizontal',
          right: '10%',
          data: this.dataLine,
          lineStyle: {
            width: 2,
            height: 'solid',
          },
        },
        xAxis: {
          name: '日期',
          nameTextStyle: {
            color: '#595959',
          },
          axisTick: {
            alignWithLabel: true,
          },
          data: this.data[this.dataLine[0]].map((it) => it.date),
        },
        yAxis: {
          nameTextStyle: {
            align: 'center',
            color: '#595959',
          },
        },
        color: ['#3aa3fc', '#91fc15', '#7f15fc', '#5B8FF9', '#ae570d'],
        series: serverData,
      };
      myChart.setOption(option);
    },
    async typeChange () {
      await this.getData();
      switch (this.tabType) {
        case '本周':
          this.setOption('echartBox1');
          break;
        case '本月':
          this.setOption('echartBox2');
          break;
        case '本年':
          this.setOption('echartBox3');
          break;
      }
    },
  },
};
</script>

<style>
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #73a0fa;
  border-color: #73a0fa;
}
.el-radio-button__inner:hover {
  color: #73a0fa;
}
.tabType .el-tabs__nav-wrap::after {
  height: 0;
}
.tabType .el-tabs__item {
  height: 0;
}
.tabType .el-tabs__header {
  margin: 0;
}
.tabType .el-tabs__nav {
  height: 0;
}
</style>
